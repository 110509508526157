import React from "react";
import { Outlet } from "react-router-dom";
import { LandingFooter } from "../../pages/LandingPage/components/LandingFooter";

export const LandingLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/*<LandingHeader />*/}
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
      <LandingFooter />
    </div>
  );
};
