import React from "react";
import { ReactComponent as LogoIcon } from "../../../../assets/logo.svg";
// import { ReactComponent as TikTokIcon } from "../../../../assets/tiktok.svg";
// import { ReactComponent as InstagramIcon } from "../../../../assets/instagram.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../utils/routes";

export const LandingFooter = () => {
  return (
    <div className="sm:mx-[10vw] mx-4 mb-8">
      <footer className="w-full flex flex-col gap-y-5 min-[834px]:flex-row min-[834px]:justify-between items-start min-[834px]:items-center mt-14 z-10 relative">
        <Link to={ROUTES.LANDING}>
          <LogoIcon />
        </Link>
        <p className="font-semibold text-white text-sm flex flex-col">
          <Link to={ROUTES.LANDING}>Copyright 2024 Pop Mystic LLC</Link>
          <Link to={ROUTES.TERMS}>Terms and Conditions</Link>
        </p>
        <p className="font-semibold text-white text-sm">info@popmystic.com</p>
        {/*<div className="flex gap-[25px]">*/}
        {/*  <TikTokIcon />*/}
        {/*  <InstagramIcon />*/}
        {/*</div>*/}
      </footer>
    </div>
  );
};
