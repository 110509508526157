import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "../../components";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";
import { ReactComponent as ResetIcon } from "../../assets/reset.svg";
import { useMutation } from "react-query";
import User, { IEditUser } from "../../utils/api/User";
import { Id, toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalStore } from "../../zustant/provider/global-store-provider";

interface FileWithBase64 extends File {
  base64?: string;
}

const prices = [
  {
    title: "Standart",
    price: "9",
    promptsAmount: "100",
  },
  {
    title: "Comfort",
    price: "19",
    promptsAmount: "1000",
    popular: true,
  },
  {
    title: "VIP",
    price: "59",
    promptsAmount: "10,000",
  },
];

export const ProfilePage = () => {
  const userData = useGlobalStore((state) => state.user);
  const setUserData = useGlobalStore((state) => state.setUser);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null);
  // const [password, setPassword] = useState<string>("");
  const [file, setFile] = useState<File | FileWithBase64 | null>(null);
  const [base64URL, setBase64URL] = useState<string>("");
  const [disabled, setDisabled] = useState(true);
  const { user, logout } = useAuth0();

  useEffect(() => {
    if (
      name === userData?.fullName &&
      email === userData?.email &&
      base64URL === userData?.imageUrl
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name, email, base64URL]);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const baseURL = reader.result as string;
        resolve(baseURL);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      console.log(selectedFile);

      getBase64(selectedFile)
        .then((result) => {
          const fileWithBase64 = selectedFile as FileWithBase64;
          fileWithBase64.base64 = result;
          console.log("File Is", fileWithBase64);
          setBase64URL(result);
          setFile(fileWithBase64);
        })
        .catch((err) => {
          console.log(err);
        });

      setFile(selectedFile);
    }
  };

  let toastId: Id;

  const editUserMutation = useMutation({
    mutationFn: (userData: IEditUser) => {
      return User.editUser(userData);
    },
    onMutate: () => {
      toastId = toast.loading("Loading...");
    },
    onSuccess: (data) => {
      // userQuery.refetch();
      setDisabled(true);
      setUserData(data.data);
      toast.update(toastId, {
        render: "Profile has been updated!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.message) {
        toast.update(toastId, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    },
  });

  const editUser = () => {
    editUserMutation.mutate({
      fullName: name,
      imageUrl: base64URL as string,
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    if (user)
      User.getUser()
        .then((data) => {
          setUserData(data.data);
          setEmail(data.data?.email || "");
          setName(data.data?.fullName || "");
          setBase64URL(data.data?.imageUrl ?? "");
        })
        .catch((err) => {
          if (err.response.status === 409) {
            User.createUser({
              email: user?.email || "",
              imageUrl: user?.picture || "",
              fullName: user?.name || "",
            }).then((data) => {
              setUserData(data.data);
              setEmail(data.data?.email || "");
              setName(data.data?.fullName || "");
              setBase64URL(data.data?.imageUrl ?? "");
            });
          }
        });
  }, [user]);

  const onChangeImage = () => {
    // `current` points to the mounted file input element
    inputFileRef?.current?.click();
  };

  return (
    <div className="md:px-28 px-4 md:pt-20 md:pb-11 py-4 h-full w-full">
      <h5 className="font-rokkitt font-semibold text-white text-3xl sm:text-6xl mb-8">
        User Profile Information
      </h5>
      <div className="flex items-center gap-3 sm:gap-8 mb-6">
        {base64URL ? (
          <img
            src={base64URL}
            className="rounded-full w-[60px] h-[60px] sm:w-[90px] sm:h-[90px]"
            alt=""
          />
        ) : (
          <div className="rounded-full w-[60px] h-[60px] sm:w-[90px] sm:h-[90px] bg-gray shrink-0" />
        )}
        {/*Avatat*/}
        <div className="flex flex-col gap-2">
          <p className="font-rokkitt font-bold text-white text-lg sm:text-xl">
            {userData?.fullName}
          </p>
          <div className="flex gap-4 items-center">
            <input
              accept="image/png, image/gif, image/jpeg"
              type="file"
              id="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
            <Button
              Icon={<UploadIcon className="flex-shrink-0" />}
              onClick={onChangeImage}
              variant="outline"
              label="Upload image"
              extraStyles="px-4 py-[8px] w-fit"
            />
            <Button
              onClick={() => setBase64URL("")}
              variant="fill"
              label="Remove"
              extraStyles="px-4 py-[8px] w-min bg-[#5D3C75]"
            />
          </div>
        </div>
      </div>
      <div className="mb-6">
        <p className="font-rokkitt font-bold text-white text-lg sm:text-xl mb-[14px]">
          Personal Information
        </p>
        <div className="flex flex-1 flex-col w-full sm:flex-row gap-6">
          <Input
            value={name}
            changeValue={setName}
            label="Full name"
            extraStyles="!bg-dark"
            containerStyles="flex flex-1"
          />
          <Input
            value={email}
            changeValue={setEmail}
            disabled
            label="Email address"
            extraStyles="!bg-dark"
            containerStyles="flex flex-1"
          />
        </div>
        <div className="flex items-end mt-5 gap-6">
          {/*<Input*/}
          {/*  value={password}*/}
          {/*  changeValue={setPassword}*/}
          {/*  label="Password"*/}
          {/*  password*/}
          {/*  extraStyles="!bg-dark"*/}
          {/*  containerStyles="flex flex-1 max-w-[204px] sm:max-w-[344px]"*/}
          {/*/>*/}
          <Button
            Icon={<ResetIcon className="flex-shrink-0" />}
            disabled={disabled}
            onClick={() => editUser()}
            label="Save"
            variant="outline"
            extraStyles="px-4 py-[10px] max-w-[140px] h-[52px]"
          />
        </div>
      </div>

      <button
        className="font-rokkitt font-bold text-white text-lg sm:text-xl mt-4"
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};
