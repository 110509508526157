import React from "react";
import { Button } from "../../../../components";
import { SectionWithBackground } from "../SectionWithBackground";
import Image from "../../../../assets/Landing_Page_PMGPT.png";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "../../../../utils/routes";

export const GetStarted = () => {
  const { loginWithRedirect, logout, isLoading, isAuthenticated } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: ROUTES.CHAT,
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <SectionWithBackground
      extraStyles="mt-20"
      title="The Premier Artificial Intelligence Tool for Film and Television Research"
      description="Explore new dimensions of film and television media with Pop Mystic GPT. Pop Mystic GPT is enhanced and customized to discuss dialog from 250,000 titles in word-for-word detail.
<br/><br/>
Leveraging its unique dataset with the power of AI, Pop Mystic GPT offers a new way to engage with media on a qualitative level as well.
<br/><br/>
Conduct quantitative research by generating spreadsheets for “big data” analysis of media discourse that has previously been unavailable for scholars of audio visual media."
    >
      {!isLoading && (
        <div className="hidden sm:block absolute top-0 right-0 w-[128px]">
          {isAuthenticated ? (
            <Button label="Log out" onClick={handleLogout} />
          ) : (
            <Button label="Sign In" onClick={handleLogin} />
          )}
        </div>
      )}

      <div className="sm:w-[440px] m-auto mt-8">
        <Button
          label="Read the Pop Mystic GPT White Paper"
          onClick={() => window.open("/PopMysticTheWhitePaper.pdf", "_blank")}
        />
      </div>

      <p className="font-nunito text-white mt-12 sm:mt-32 sm:mx-8">
        Pop Mystic GPT redefines media exploration by integrating deep cultural
        insights with advanced AI technology. This isn&apos;t just a new way to
        engage with pop culture—it&apos;s the future of how we understand and
        interact with media. Combining quantitative, big data analysis with
        qualitative insights means that every conversation enriches your
        understanding of the most important media form of our time in ways that
        were previously unavailable.
      </p>
      <img
        src={Image}
        className="hidden sm:block mx-auto shadow-imageShadow rounded-2xl mt-9"
      />
    </SectionWithBackground>
  );
};
