import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { BrowserRouter } from "react-router-dom";
import { GlobalStoreProvider } from "./zustant/provider/global-store-provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <GlobalStoreProvider>
          <App />
        </GlobalStoreProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      toastStyle={{ backgroundColor: "#200436" }}
    />
  </QueryClientProvider>,
);
