import React, { FC } from "react";

interface InputProps {
  label: string;
  value: string;
  changeValue: (value: string) => void;

  [key: string]: any;
}

export const ModalInput: FC<InputProps> = ({
  label,
  value,
  changeValue,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-1.5 z-10">
      <input
        type={"text"}
        placeholder={label}
        className={`px-4 py-[18px] focus:border-primary focus:border bg-dark rounded-xl outline-none text-white font-nunito font-normal`}
        value={value}
        onChange={(e) => changeValue(e.target.value)}
        {...props}
      />
    </div>
  );
};
