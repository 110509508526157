import React, { FC } from "react";
import { ReactComponent as LogoIcon2 } from "../../../../assets/logo2.svg";

interface SectionWithBackgroundProps {
  children: React.ReactNode;
  title: string;
  description: string;
  extraStyles?: string;
}

export const SectionWithBackground: FC<SectionWithBackgroundProps> = ({
  children,
  title,
  description,
  extraStyles,
}) => {
  return (
    <section className="relative w-full">
      <div className="section" />
      <div className="relative z-10 sm:mx-[10vw] mx-4">
        <div className={`text-center lg:mx-44 ${extraStyles}`}>
          <div className='flex gap-10 mb-6'>
            <LogoIcon2 width={740} height={140} />
          </div>
          <h1 className="font-rokkitt font-semibold text-white sm:text-5xl text-3xl">
            {title}
          </h1>
          <p
            className="font-nunito text-gray lg:mx-20 mt-4"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        {children}
      </div>
    </section>
  );
};
