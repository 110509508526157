import React, { useEffect } from "react";
import { ChatPage, LandingPage, ProfilePage, TermsOfUse } from "./pages";
import { Navigate, Routes, Route } from "react-router-dom";
import { ROUTES } from "./utils/routes";
import { LandingLayout } from "./layouts/LandingLayout/LandingLayout";
import { ChatLayout } from "./layouts/ChatLayout/ChatLayout";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import { CallbackPage } from "./pages/CallbackPage";

export default function App() {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  console.log("isLoading", isLoading);
  console.log("isAuthenticated", isAuthenticated);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem("accessToken", accessToken);
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated]);

  // const router = createBrowserRouter([
  //   {
  //     path: "/auth",
  //     element: <AuthLayout />,
  //     children: [
  //       { index: true, element: <LoginPage /> },
  //       { path: ROUTES.LOGIN, element: <LoginPage /> },
  //       {
  //         path: ROUTES.SIGN_UP,
  //         element: <SignUpPage />,
  //       },
  //       {
  //         path: "*",
  //         element: <Navigate to={ROUTES.LOGIN} replace />,
  //       },
  //     ],
  //   },
  //   {
  //     path: "/",
  //     element: <LandingLayout />,
  //     children: [
  //       {
  //         path: "*",
  //         element: <Navigate to={ROUTES.LANDING} replace />,
  //       },
  //       { index: true, element: <LandingPage /> },
  //       { path: "/", element: <LandingPage /> },
  //       { path: ROUTES.TERMS, element: <TermsOfUse /> },
  //     ],
  //   },
  //   {
  //     path: ROUTES.CHAT,
  //     element: <ChatLayout />,
  //     children: [
  //       {
  //         path: "*",
  //         element: <Navigate to={ROUTES.CHAT} replace />,
  //       },
  //       { path: `${ROUTES.CHAT}/:id`, element: <ChatPage /> },
  //       { path: ROUTES.PROFILE, element: <ProfilePage /> },
  //     ],
  //     loader: () => {
  //       if (!localStorage.getItem("accessToken")) {
  //         return redirect("/");
  //       }
  //       return null;
  //     },
  //   },
  // ]);

  // return <RouterProvider router={router} />;
  return (
    <Routes>
      <Route path="/callback" element={<CallbackPage />} />

      {/* Landing Page Routes */}
      <Route path="/" element={<LandingLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="/" element={<LandingPage />} />
        <Route path={ROUTES.TERMS} element={<TermsOfUse />} />
        <Route path="*" element={<Navigate to={ROUTES.LANDING} replace />} />
      </Route>

      {/* Chat Routes */}
      <Route
        path={ROUTES.CHAT}
        element={<AuthenticationGuard component={ChatLayout} />}
      >
        <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
        <Route path={`${ROUTES.CHAT}/:id`} element={<ChatPage />} />
        <Route path="*" element={<Navigate to={ROUTES.CHAT} replace />} />
      </Route>
    </Routes>
  );
}
