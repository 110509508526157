import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { useQuery } from "react-query";
import Chat from "../../../utils/api/Chat";
import { IChat } from "../../../utils/types/chat";
import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalStore } from "../../../zustant/provider/global-store-provider";

export const SideBarContent = () => {
  const userData = useGlobalStore((state) => state.user);

  const chatsQuery = useQuery("chats", Chat.getAllChats);
  const params = useParams();
  const { user } = useAuth0();

  const chats: IChat[] = useMemo(() => {
    return chatsQuery.data?.reverse() ?? [];
  }, [chatsQuery.data]);

  return (
    <div className="flex flex-col flex-1 justify-between mt-6 max-h-screen">
      <div>
        <p className="font-nunito font-bold text-white text-sm mx-3">
          Recent Chat
        </p>
        <div className="flex flex-col mt-4 gap-1 overflow-y-scroll no-scrollbar max-h-[60vh]">
          {chats.map((chat) => (
            <Link
              key={chat.id}
              to={ROUTES.CHAT + `/${chat.id}`}
              className="cursor-pointer"
            >
              <div
                className={`${chat.id === params.id ? "bg-lightDark" : "bg-dark"} rounded-xl`}
              >
                <p className="font-nunito font-normal text-white text-base px-3 py-1 mx-3">
                  {chat.title}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="mx-5">
        <Link
          className="flex gap-5 items-center cursor-pointer"
          to={ROUTES.PROFILE}
        >
          {/* <div className="rounded-full w-12 h-12 bg-gray shrink-0" />
           */}
          {userData?.imageUrl || user?.picture ? (
            <img
              src={userData?.imageUrl || user?.picture}
              className="rounded-full w-12 h-12"
              alt=""
            />
          ) : (
            <div className="rounded-full w-12 h-12 bg-gray shrink-0" />
          )}
          {/*Avatat*/}

          {/*Avatat*/}
          <p className="font-nunito text-white font-normal text-sm">
            {userData?.fullName || user?.name}
          </p>
          {/*NAME*/}
        </Link>
      </div>
    </div>
  );
};
