import React from "react";
import { ReactComponent as SendIcon } from "../../../../assets/send.svg";

interface IChatInputProps {
  value: string;
  setValue: (value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}

export const ChatInput: React.FC<IChatInputProps> = ({
  value,
  setValue,
  onSubmit,
  isLoading,
}) => {
  return (
    <form
      className="w-full px-5 flex justify-between items-center py-[18px] bg-dark rounded-[40px]"
      onSubmit={onSubmit}>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="flex flex-1 font-normal text-white outline-none bg-dark font-nunito"
        placeholder="Enter a prompt here"
      />
      {isLoading ? (
        <div
          className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      ) : (
        <button type="submit">
          <SendIcon />
        </button>
      )}
    </form>
  );
};
