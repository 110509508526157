import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

interface Props {
  component: React.ComponentType<object>;
}

export const AuthenticationGuard: React.FC<Props> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        {/*<PageLoader />*/}
      </div>
    ),
  });

  return <Component />;
};
