import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as BurgerIcon } from "../../assets/burger.svg";
import { ROUTES } from "../../utils/routes";
import { useQuery } from "react-query";
import User from "../../utils/api/User";
import { SideBarContent } from "./SideBarContent";
import { CreateChatModal } from "./CreateChatModal/CreateChatModal";

export const ChatLayout = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [createChatModalVisible, setCreateChatModalVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const openModal = () => {
    setCreateChatModalVisible(true);
  };

  const closeModal = () => {
    setCreateChatModalVisible(false);
  };

  // useQuery("getuser", User.getUser, {
  //   onError: (error) => {
  //     localStorage.removeItem("accessToken");
  //     navigate(ROUTES.LANDING);
  //     console.log(error, "err");
  //   },
  // });

  return (
    <div className="flex md:flex-row flex-col min-h-screen">
      <div className="hidden md:flex md:flex-col md:w-[312px] md:min-w-[312px] bg-dark px-3 py-6 h-full min-h-screen">
        <div className="mx-5">
          <LogoIcon width={178} />
          <div className="mt-10">
            <div
              className="bg-primary w-fit items-center flex px-[18px] py-[14px] gap-2 rounded-[40px] cursor-pointer"
              onClick={openModal}
            >
              <EditIcon />
              <p className="font-nunito font-semibold text-lg text-dark">
               New Chat
              </p>
            </div>
          </div>
        </div>
        <SideBarContent />
      </div>
      <div className="md:hidden">
        <div className="bg-dark flex justify-between py-[14px] px-4 items-center">
          <div
            className="cursor-pointer"
            onClick={() => setShowSideBar(!showSideBar)}
          >
            <BurgerIcon width={26} height={26} color="#FFF4D8" />
          </div>
          <LogoIcon height={22} />
          <div onClick={openModal}>
            <EditIcon width={26} height={26} color="#FFF4D8" />
          </div>
        </div>
        {showSideBar ? (
          <div
            className="absolute top-0 h-screen w-screen bg-[#0000009C] z-[100]"
            onClick={() => setShowSideBar(!showSideBar)}
          >
            <div
              className="bg-dark py-5 px-2 w-[70%] h-full flex flex-col"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between">
                <div
                  className="cursor-pointer"
                  onClick={() => setShowSideBar(!showSideBar)}
                >
                  <BurgerIcon width={26} height={26} color="#FFF4D8" />
                </div>
                <div onClick={openModal}>
                  <EditIcon width={26} height={26} color="#FFF4D8" />
                </div>
              </div>
              {/* <div className='flex flex-col flex-1 justify-between mt-6'>
              <div>
                <p className='font-nunito font-bold text-white text-xs mx-3'>Recent</p>
              </div> */}
              {/*Chats List*/}
              <SideBarContent />
              {/* </div> */}
            </div>
          </div>
        ) : null}
      </div>
      <div className="bg-lightDark chat-bg flex-1">
        <Outlet />
      </div>
      {createChatModalVisible ? <CreateChatModal onClose={closeModal} /> : null}
    </div>
  );
};
