import { api } from "./api";

const getChatMessages = async (id: string) => {
  const response = await api.get(`/message/${id}`);
  return response.data;
};

const sendMessage = async (id: string, content: string) => {
  const response = await api.post(`/message/${id}`, { content });
  return response.data;
};

const downloadXLSX = async (chatId: string, messageId: string) => {
  const response = await api.post(
    `/message/download/xlsx`,
    {
      chatId,
      messageId,
    },
    {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    },
  );
  return response.data;
};

export default { downloadXLSX, getChatMessages, sendMessage };
