import { createStore } from "zustand/vanilla";

export type UserType = {
  email: string;
  imageUrl: string;
  fullName: string;
};

export type GlobalState = {
  user: UserType | null;
};

export type GlobalActions = {
  setUser: (user: any) => void;
};

export type GlobalStore = GlobalState & GlobalActions;

export const defaultGlobalState: GlobalState = {
  user: null,
};

export const createGlobalStore = (
  initState: GlobalState = defaultGlobalState,
) => {
  return createStore<GlobalStore>((set) => ({
    ...initState,
    setUser: (user: any) => set(() => ({ user })),
  }));
};
