import { api } from "./api";

const getUser = async () => {
  const response = await api.get("/user");
  return response.data;
};

export interface ICreateUser {
  fullName: string;
  email: string;
  imageUrl?: string;
}

const createUser = async (userData: ICreateUser) => {
  const response = await api.post(`/user`, userData);
  return response.data;
};

export interface IEditUser {
  fullName: string;
  imageUrl?: string;
}

const editUser = async (userData: IEditUser) => {
  const response = await api.put(`/user`, userData);
  return response.data;
};

export default { getUser, editUser, createUser };
