import React, { FC } from "react";

interface InputProps {
  label: string;
  value: string;
  changeValue: (value: string) => void;
  password?: boolean;
  error?: boolean;
  errorMessage?: string;
  extraStyles?: string;
  containerStyles?: string;
  disabled?: boolean;
}

export const Input: FC<InputProps> = ({
  label,
  value,
  changeValue,
  password,
  error,
  errorMessage,
  extraStyles,
  containerStyles,
  disabled,
}) => {
  return (
    <div className={`flex flex-col gap-1.5 z-10 ${containerStyles}`}>
      <p className="font-nunito font-medium text-xs text-white">{label}</p>
      <input
        disabled={disabled}
        type={password ? "password" : "text"}
        placeholder={label}
        className={`px-4 py-[14px] focus:border-primary focus:border bg-lightDark rounded-xl outline-none text-white font-nunito font-normal ${error ? "border border-red" : ""}${extraStyles ? extraStyles : ""}`}
        value={value}
        onChange={(e) => changeValue(e.target.value)}
      />
      {error && errorMessage ? (
        <p className="font-nunito font-medium text-xs text-red">
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
};
