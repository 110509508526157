import React from "react";

interface IModalProps {
  title: string;
  children: React.ReactNode;
  buttonTitle: string;
  onClose: () => void;
  onButtonClick: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  title,
  children,
  buttonTitle,
  onClose,
  onButtonClick,
}) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-dark bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-lightDark text-left shadow-xl transition-all my-8 w-full max-w-lg">
            <div className="bg-lightDark px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex w-full items-center">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <h3
                    className="font-rokkitt text-xl font-semibold leading-6 text-white"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <div className="mt-2 w-full">{children}</div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={onButtonClick}
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 sm:px-5 sm:py-3 text-sm font-nunito font-semibold text-dark shadow-sm bg-primary sm:ml-3 sm:w-auto"
              >
                {buttonTitle}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-dark px-3 py-2 sm:px-5 sm:py-3 text-sm font-nunito font-semibold text-white shadow-sm sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
