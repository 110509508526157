import { type AxiosResponse } from "axios";
import clsx, { type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getFileNameFromHeaderValue(headerValue: string) {
  const fileName = headerValue.split("filename=").at(1) || "";

  return fileName;
}

export function downloadBlob(response: AxiosResponse) {
  const blob = response.data as Blob;

  const fileName = getFileNameFromHeaderValue(
    response.headers["content-disposition"]
  );

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
