import React from "react";

import { GetStarted } from "./components/GetStarted";
// import { FirstSection } from "./components/FirstSection";

export const LandingPage = () => {
  return (
    <div>
      <GetStarted />
      {/*<FirstSection />*/}
      {/*<SecondSection />*/}
      {/*<Prices />*/}
      {/*<ThirdSection />*/}
      {/*<FAQ />*/}
    </div>
  );
};
