import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  onClick?: () => void;
  variant?: "outline" | "fill";
  extraStyles?: string;
  Icon?: React.ReactNode;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  variant,
  extraStyles,
  Icon,
  disabled,
  ...props
}) => {
  const outlined = variant === "outline";
  return (
    <button
      disabled={disabled}
      className={`w-full flex gap-2 justify-center items-center text-center font-nunito font-semibold text-base text-dark cursor-pointer bg-primary rounded-xl py-4 ${outlined ? "bg-transparent border-primary border text-primary" : ""} ${extraStyles ? extraStyles : ""} ${disabled ? "opacity-50 cursor-default" : ""}`}
      onClick={onClick}
      {...props}>
      {Icon ? Icon : null}
      <p>{label}</p>
    </button>
  );
};
