import React, { useState } from "react";
import { Modal } from "../../../components";
import { ModalInput } from "./ModalInput";
import { Id, toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import Chat from "../../../utils/api/Chat";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";

interface ICreateChatModal {
  onClose: () => void;
}

export const CreateChatModal: React.FC<ICreateChatModal> = ({ onClose }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [chatName, setChatName] = useState<string>("");
  let toastId: Id;
  const chatMutation = useMutation({
    mutationFn: (title: string) => {
      return Chat.createChat(title);
    },
    onMutate: () => {
      toastId = toast.loading("Chat is creating...");
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: "chats" });
      navigate(ROUTES.CHAT + `/${data.data.id}`);
      toast.update(toastId, {
        render: "Chat has been created!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    },
    onError: () => {
      toast.update(toastId, {
        render: "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    },
  });
  const onCreate = () => {
    chatMutation.mutate(chatName);
    onClose();
  };
  return (
    <Modal
      title="Create a chat"
      buttonTitle="Create"
      onButtonClick={onCreate}
      onClose={onClose}
    >
      <ModalInput
        value={chatName}
        changeValue={setChatName}
        label="Enter chat title"
      />
    </Modal>
  );
};
