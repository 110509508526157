import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { Button } from "../../components";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { ReactComponent as BurgerIcon } from "../../assets/burger.svg";
import { useAuth0 } from "@auth0/auth0-react";

export const TermsOfUse = () => {
  const { loginWithRedirect, logout, isLoading, isAuthenticated } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: ROUTES.CHAT,
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <div className="sm:mx-[10vw] mx-4">
        <header className="w-full flex justify-between items-center mt-14 z-10 relative">
          <Link to={ROUTES.LANDING}>
            <LogoIcon />
          </Link>

          <div className="w-[127px] hidden min-[834px]:flex">
            {!isLoading && (
              <div className="hidden sm:block absolute top-0 right-0 w-[128px]">
                {isAuthenticated ? (
                  <Button label="Log out" onClick={handleLogout} />
                ) : (
                  <Button label="Sign In" onClick={handleLogin} />
                )}
              </div>
            )}{" "}
          </div>
          <div className="max-[834px]:flex hidden cursor-pointer">
            <BurgerIcon />
          </div>
        </header>
      </div>

      <div className="terms-bg flex-1">
        <div className="sm:mx-[10vw] mx-4 my-20">
          <h1 className="font-rokkitt font-semibold sm:text-6xl text-3xl text-white">
            Terms of Use
          </h1>
          <div className="flex flex-col gap-5 mt-6 mb-8">
            <p className="font-nunito text-sm text-white">
              1. Acceptance of Terms: By accessing and using the Pop Mystic GPT
              platform, you confirm that you have read, understood, and agree to
              be bound by these terms. If you do not agree with any part of
              these terms, you should not use the services provided by Pop
              Mystic GPT.
            </p>
            <p className="font-nunito text-sm text-white">
              2. Intellectual Property and Fair Use: The content available
              through Pop Mystic GPT is intended for purposes such as criticism,
              comment, news reporting, teaching, scholarship, and research,
              which are recognized as Fair Use under copyright law. Users are
              encouraged to conduct their own due diligence to ensure that the
              use of material complies with all applicable laws and to cite
              sources appropriately in academic or other use cases.
            </p>
            <div className="font-nunito text-sm text-white">
              3. User Conduct Users of Pop Mystic GPT agree not to:
              <ul className="list-disc ml-5">
                <li>
                  Use the service for any unlawful purposes or in violation of
                  any applicable laws or regulations.
                </li>
                <li>
                  Infringe on the intellectual property rights of others or the
                  Fair Use provisions as outlined.
                </li>
                <li>
                  Misrepresent the source, identity, or content provided through
                  the service.
                </li>
              </ul>
            </div>
            <p className="font-nunito text-sm text-white">
              4. Limitation of Liability: Pop Mystic GPT, its owners, employees,
              and affiliates are not liable for any direct, indirect,
              incidental, special, consequential, or punitive damages resulting
              from the use of or inability to use the service.
            </p>
            <p className="font-nunito text-sm text-white">
              5. Content Generated by AI: Pop Mystic GPT generates content based
              on existing media data. This content is inherently speculative and
              should not be relied upon as authoritative or error-free. The
              platform disclaims any liability arising from reliance on
              AI-generated content.
            </p>
            <p className="font-nunito text-sm text-white">
              6. Modifications to Terms: We reserve the right to modify these
              terms at any time. Your continued use of the service after such
              modifications confirms your acceptance of the new terms.
            </p>
          </div>
          <h5 className="font-rokkitt text-xl text-white font-semibold">
            Disclaimer
          </h5>
          <div className="flex flex-col gap-5 mt-5">
            <p className="font-nunito text-sm text-white">
              General Disclaimer - The information provided by Pop Mystic GPT is
              on an &quot;as is&quot; basis. While we strive to provide accurate
              and up-to-date information, there are no guarantees regarding the
              accuracy, relevance, timeliness, or completeness of any
              information.
            </p>
            <p className="font-nunito text-sm text-white">
              AI-Generated Content Disclaimer - Content generated by Pop Mystic
              GPT is derived from a combination of pre-existing data and AI
              interpretations. This content may not accurately represent the
              facts or context of the referenced materials and should be
              independently verified before being cited in research.
            </p>
            <p className="font-nunito text-sm text-white">
              Fair Use Disclaimer - Pop Mystic GPT is designed to support and
              facilitate research, teaching, and scholarship under the
              principles of Fair Use. It is the responsibility of each user to
              ensure that their use of materials from Pop Mystic GPT complies
              with Fair Use laws and does not infringe on the rights of
              copyright holders.
            </p>
            <p className="font-nunito text-sm text-white">
              Liability Disclaimer - The creators of Pop Mystic GPT shall not be
              held liable for any improper or incorrect use of the information
              or services on this website and assume no responsibility for
              anyone&apos;s use of the information or services.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
