import { api } from "./api";

const getChat = async (id: string) => {
  const response = await api.get(`/chat/:${id}`);
  return response.data;
};

const editChat = (chatId: string, message: string) => {
  return api.put(`/chat/${chatId}`, { title: message });
};

const getAllChats = async () => {
  const response = await api.get("/chat");
  return response.data.data;
};

const createChat = (title: string) => {
  return api.post("/chat", { title });
};

export default { getAllChats, editChat, createChat, getChat };
