import React, { useMemo } from "react";
import { ReactComponent as AssistantLogo } from "../../../../assets/assistantLogo.svg";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { cn } from "../../../../utils/general";
import { IMessage } from "../../../../utils/types/chat";
import Messages from "../../../../utils/api/Messages";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface IMessageProps {
  message: string;
  previousMessage?: IMessage;
}

const hasMarkdown = (text: string) => {
  // Regular expressions for common Markdown patterns
  const markdownPatterns = [
    // /(^|\s)(#{1,6}\s)/,                     // Headers (e.g., # Header, ## Header)
    // /(\*\*|__)(.*?)\1/,                     // Bold (e.g., **text** or __text__)
    // /(\*|_)(.*?)\1/,                        // Italics (e.g., *text* or _text_)
    // /\[(.*?)\]\((.*?)\)/,                   // Links (e.g., [text](url))
    // /(\n\s*[-*]\s+)/,                       // Unordered lists (e.g., - item or * item)
    // /(\n\s*\d+\.\s+)/,                      // Ordered lists (e.g., 1. item)
    // /`{1,3}[^`]*`{1,3}/,                    // Inline code or code blocks (e.g., `code`, ```code```)
    // />\s?/,                                 // Blockquotes (e.g., > quote)
    /\|(.+?)\|/, // Simple table row (e.g., | Cell 1 | Cell 2 |)
    /\|([-:]+)\|/, // Table header separator (e.g., |---|---|)
  ];

  // Check if the text matches any of the Markdown patterns
  return markdownPatterns.some((pattern) => pattern.test(text));
};

export const ChatMessage: React.FC<IMessageProps> = ({
  message,
  previousMessage,
}) => {
  const params = useParams();
  const [downloading, setDownloading] = React.useState<boolean>(false);

  const showDownloadBtn = useMemo(() => hasMarkdown(message), [message]);

  const onDownload = () => {
    console.log("previousMessage", previousMessage);

    if (!previousMessage) return;
    setDownloading(true);
    const toastId = toast.loading("Downloading the file...");
    Messages.downloadXLSX(params.id as string, previousMessage.id)
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "output.xlsx"; // Specify the filename
        document.body.appendChild(a);
        a.click(); // Simulate a click to download
        window.URL.revokeObjectURL(url); // Clean up the URL.createObjectURL
        a.remove(); // Remove the link from the document
      })
      .then(() => {
        toast.update(toastId, {
          render: "Downloaded!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .finally(() => setDownloading(false));
  };

  return (
    <div className="flex flex-row items-start justify-start gap-2 px-5 py-2 bg-dark rounded-2xl">
      <AssistantLogo width={42} height={42} className="shrink-0" />
      <div className="text-sm font-medium text-white font-nunito custom-table">
        <Markdown
          urlTransform={(url) => url}
          remarkPlugins={[remarkGfm]}
          components={{
            table: ({ children, ...props }) => (
              <table className="my-4" {...props}>
                {children}
              </table>
            ),
            // a: ({ children, href, ...props }) => {
            //   if (href?.startsWith("http"))
            //     return (
            //       <a href={href} {...props}>
            //         {children}
            //       </a>
            //     );
            //
            //   return (
            //     <DownloadFileButton fileId={href || ""}>
            //       {children}
            //     </DownloadFileButton>
            //   );
            // },
            code: ({ children, ...props }) => (
              <code className="my-4" {...props}>
                {children}
              </code>
            ),
          }}
        >
          {message}
        </Markdown>
        {showDownloadBtn && (
          <>
            <div style={{ height: 20 }} />
            <button
              disabled={downloading}
              onClick={onDownload}
              style={{ color: "rgb(14 165 233)" }}
              className={cn("cursor-pointer hover:underline", {
                "opacity-30 cursor-default": downloading,
              })}
            >
              You can download spreadsheet by clicking here
            </button>
          </>
        )}
      </div>
    </div>
  );
};
