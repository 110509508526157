import React from "react";
import { ReactComponent as EditIcon } from "../../../../assets/edit.svg";
import { useGlobalStore } from "../../../../zustant/provider/global-store-provider";
import { useAuth0 } from "@auth0/auth0-react";

interface IMessageProps {
  message: string;
}

export const UserMessage: React.FC<IMessageProps> = ({ message }) => {
  const userData = useGlobalStore((state) => state.user);
  const { user } = useAuth0();

  return (
    <div className="flex justify-between flex-row items-center px-5 py-2">
      <div className="flex flex-row gap-2 items-center">
        {userData?.imageUrl || user?.picture ? (
          <img
            src={userData?.imageUrl || user?.picture}
            className="rounded-full w-11 h-11"
            alt=""
          />
        ) : (
          <div className="rounded-full w-11 h-11 bg-gray shrink-0" />
        )}
        {/*Avatat*/}
        <p className="font-nunito font-medium text-white text-sm">{message}</p>
      </div>
      <div className="cursor-pointer">
        <EditIcon className="text-white" />
      </div>
    </div>
  );
};
